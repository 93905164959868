.App {
  text-align: center;
}

* {
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-main {
  background-image: url('./img/bg.png'); /* Replace with your image path */
  background-size: cover; /* Cover the entire div */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background from repeating */
  height: 90vh;
  margin-top: 10vh;
}

.home-main-container {
  margin-left: 10%;
  padding-top: 8%;
}

.home-title {
  color: white;
  font-size: 4.7vw;
  font-weight: bold;
  font-family: 'Helvetica Neue', sans-serif;
  width: 60%;
  margin-bottom: 2%;
  max-width: 80%;
}

.vector-img {
  width: 50%;
  height: auto;
}

.home-desc {
  margin-top: 2%;
  color: white;
  font-family: 'Helvetica Neue', sans-serif;
  width: 50%;
  font-size: 1.8vw;
}

/*Bobbing arrow animation */
.arrow {
    position: absolute;
    top: 93%;
    left: 50%;
    transform: translate(-50%,-0%);
}
.arrow span{
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 4s infinite;
}
.arrow span:nth-child(2){
    animation-delay: -0.4s;
}
.arrow span:nth-child(3){
    animation-delay: -0.8s;
}
/*key frames for arrow animation */
@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}

@media screen and (max-width: 1450px) {
  .home-title {
    font-size: 5.5vw;
    width: 60%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 55%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 50%;
    font-size: 2vw;
  }
}

@media screen and (max-width: 1250px) {
  .home-title {
    font-size: 6.2vw;
    width: 70%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 65%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 54%;
    font-size: 2.3vw;
  }
}

@media screen and (max-width: 1075px) {
  .home-title {
    font-size: 7vw;
    width: 70%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 60%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 57%;
    font-size: 3vw;
  }
}

@media screen and (max-width: 850px) {
  .home-title {
    font-size: 8vw;
    width: 80%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 75%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 65%;
    font-size: 3.4vw;
  }
}

@media screen and (max-width: 760px) {
  .home-title {
    font-size: 9.5vw;
    width: 80%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 75%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 70%;
    font-size: 3.8vw;
  }
}

@media screen and (max-width: 700px) {
  .home-title {
    font-size: 9.8vw;
    width: 100%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 90%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 80%;
    font-size: 4.5vw;
  }
}


@media screen and (max-width: 600px) {
  .home-title {
    font-size: 11vw;
    width: 100%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 90%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 80%;
    font-size: 4.5vw;
  }
}

@media screen and (max-width: 500px) {
  .home-title {
    font-size: 12vw;
    width: 100%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 90%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 80%;
    font-size: 4.8vw;
  }
}

/* Phone */
@media screen and (max-width: 450px) {
  .home-title {
    font-size: 12vw;
    width: 100%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 90%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 80%;
    font-size: 4vw;
  }

  .arrow {
    top: 100%;
  }
}

@media screen and (max-width: 380px) {
  .home-title {
    font-size: 13.5vw;
    width: 100%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 90%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 80%;
    font-size: 6vw;
    height: 10%;
  }
}

.home-section {
  min-height: 50vh;
  display: flex;
  align-items: center;
  padding-top: 10vh;
  padding-left: 5vw;
  padding-right: 5vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

#home-sector-section {
  height: 85vh;
  margin-top: 5vh;
  overflow: hidden;
}

.home-section-title {
  font-size: 40px;
  font-weight: bold;
  color: #30376B;
  justify-content: space-evenly;
  height: 10vh;
}

.line-img {
  margin: 0 3vw;
}

.sector-table {
  height: 60vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.sector-cell {
  padding: 2%;
  width: 15vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 980px) {
  #home-sector-section {
    height: auto; /* Adjust height to be auto to accommodate the vertical layout */
    margin-top: 2vh; /* Adjust top margin if needed */
  }

  .home-section-title {
    font-size: 30px; /* Adjust font size for smaller screens */
    justify-content: center; /* Center the title if needed */
  }

  .sector-table {
    flex-direction: column; /* Stack the cells vertically */
    align-items: center; /* Center the cells */
    height: auto; /* Adjust height to be auto to accommodate the new layout */
    margin-top: 3vh; /* Adjust top margin if needed */
  }

  .sector-cell {
    width: 80vw; /* Adjust the width to take more space */
    margin-bottom: 2vh; /* Add some space between the cells */
  }

  #home-sector-section .sector-img {
    width: 30%; /* Adjust the image width to fit the cell */
    height: auto;
  }

  .sector-title {
    font-size: 25px; /* Adjust the title font size for smaller screens */
  }
}

.sector-img {
  width: 75%;
  height: auto;
}

.sector-title {
  font-size: 30px;
  font-weight: bold;
  color: #30376B;
  margin-top: 3%;
}

.sector-desc {
  font-size: 20px;
  color: #30376B;
  margin-top: 3%;
}

#home-placements-section {
  height: 100vh;
  overflow: hidden;
}

#home-partners-section {
  height: 120vh;
  overflow: hidden;
  margin-bottom: 5vh;
}

.placement-table {
  height: 80vh;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 5vh;
  margin-bottom: 5vh;
  color: white;
  display: flex;
  flex-direction: column;
}

.placement-row {
  height: 20vh;
  width: 100%;
  display: flex;
}

.placement-cell {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
}

.placement-img {
  width: 100%;
  height: auto;
}

.partners-img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 900px) {
  #home-placements-section {
    height: 100vh;
    overflow: hidden;
  }

  .placement-table {
    height: 200vh;
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5vh;
    color: white;
    display: flex;
    flex-direction: column;
  }

  .placement-row {
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .placement-cell {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .placement-img {
    width: 100%;
    height: auto;
  }

  .partners-img {
    width: 100%;
    height: auto;
  }

  #home-partners-section {
    min-height: 100vh;
    overflow: hidden;
    margin-bottom: 5vh;
  }

  .partners-table {
    height: 200vh;
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5vh;
    color: white;
    display: flex;
    flex-direction: column;
  }

  .partners-row {
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .partners-cell {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 3%;
    padding-bottom: 3%;
  }
}

#home-partners-section {
  height: 70vh;
  overflow: hidden;
}

.partners-table {
  height: 40vh;
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 5vh;
  margin-bottom: 5vh;
  color: white;
  display: flex;
  flex-direction: column;
}

.partners-row {
  height: 20vh;
  width: 100%;
  display: flex;
}

.partners-cell {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
}


footer {
  height: 15.5vh;
  width: 100%;
  background-image: url('./img/footer_rect.png'); 
  background-size: cover; /* Cover the entire div */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-right {
  margin-right: 5%;
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-social {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-icons {
  margin-right: 30%;
}

.footer-icon {
  width: 50px;
  height: auto;
  color: #FFD983;
}

@media screen and (max-width: 1050px) {
  .footer-right {
    margin-left: 5%;
    margin-right: 5%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-hex {
    display: none;
  }

  .footer-social {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-icons {
    margin-right: 30%;
  }

  .footer-icon {
    width: 50px;
    height: auto;
    color: #FFD983;
  }
}

.page-header {
  margin-top: 10vh;
  height: 19vh;
  background-image: url('./img/page_rect.png'); 
  background-size: cover; /* Cover the entire div */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  padding-left: 8%;
  padding-right: 1%;
  overflow: hidden;
}

.page-header-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 100px;
  font-size: 6vw;
  font-weight: bold;
}

.partners-page-container {
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.partners-page-table {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.partners-page-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners-page-img {
  width: 50%;
  height: auto;
  padding-bottom: 5vh;
}

@media screen and (max-width: 1250px) {
  .partners-page-container {
    min-height: 80vh;
  }
}

@media screen and (max-width: 900px) {
  .partners-page-container {
    min-height: 70vh;
  }
}

@media screen and (max-width: 725px) {
  .partners-page-container {
    min-height: 60vh;
  }
}

@media screen and (max-width: 525px) {
  .partners-page-container {
    min-height: 55vh;
  }

  .partners-page-img {
    width: 70%;
    height: auto;
    padding-bottom: 5vh;
  }

}

.placement-page-container {
  max-width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  padding-top: 5vh;
  margin-bottom: 5vh;
}

.placement-page-table {
  height: 90vh;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 5vh;
  margin-bottom: 5vh;
  color: white;
  display: flex;
  flex-direction: column;
}

.placement-page-row {
  height: 20vh;
  margin-bottom: ;
  width: 100%;
  display: flex;
}

.placement-page-cell {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
}

.placement-page-img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 750px) {
  .placement-page-container {
    max-width: 150vw;
    height: 55.5vh;
    display: flex;
    justify-content: center;
    padding-top: 0vh;
    margin-bottom: 0vh;
  }

  #home-placements-section {
    height: 70vh;
    overflow: hidden;
  }

  .placement-page-table {
    height: 45vh;
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5vh;
    color: white;
    display: flex;
    flex-direction: column;
  }

  .placement-page-row {
    height: 10vh;
    width: 100%;
    display: flex;
    margin-bottom: 2vh;
  }

  .placement-page-cell {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
  }

  .placement-page-img {
    width: 100%;
    height: auto;
  }
}

.competition-page-container {
  max-width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comp-page-left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
}

.comp-page-right {
  width: 50%;
  height: 100%;
  display: flex;
  border: 1px solid white;
  align-items: center;
  justify-content: right;
}



#sc-img {
  width: 34%;
  height: 80%;
  z-index: 100;
  position: absolute;
}

#hex1 {
  position: absolute;
  transform: translateX(-100%) translateY(-65%);
}

#hex2 {
  position: absolute;
  transform: translateX(-150%) translateY(180%);
}

.comp-main-container {
  margin-left: 8%;
  margin-right: 8%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  border: 1px solid white;
  
}

.comp-title {
  color: #30376B;
  font-size: 100px;
  font-weight: bold;
  font-family: 'Helvetica Neue', sans-serif;
  margin-bottom: 4%;
  max-width: 100%;
}

.comp-desc {
  margin-top: 4%;
  color: #1C2441;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 25px;
}

.comp-app-button {
  margin-top: 5%;
  height: 75px;
  width: 225.5px;
  color: #26234F;
  font-weight: bold;
  background-color: #FED780;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-decoration: none;
  font-size: 30px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 550px) {
  .competition-page-container {
    max-width: 100vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .comp-page-left {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    padding-left: 5%;
    padding-right: 5%;
  }

  .comp-main-container {
    margin-left: 8%;
    margin-right: 8%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    border: 1px solid white;
    width: 100%;
  }

  .comp-title {
    color: #30376B;
    font-size: 12vw;
    font-weight: bold;
    font-family: 'Helvetica Neue', sans-serif;
    margin-bottom: 4%;
    max-width: 100%;
  }


  .comp-desc {
    margin-top: 4%;
    color: #1C2441;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 4.5vw;
    text-align: center;
    max-width: 100%;
  }

  .comp-app-button {
    margin-top: 5%;
    height: 75px;
    width: 225.5px;
    color: #26234F;
    font-weight: bold;
    background-color: #FED780;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    text-decoration: none;
    font-size: 30px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  }
}

.comp-app-button-container {
  display: flex;
  justify-content: center;
}

#comp-arrow {
  z-index: 101;
  position: absolute;
  width: 65%;
  transform: translateY(100%);
}

.leadership-page-container {
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 10vh;
  flex-direction: column;
}

@media screen and(max-width: 1040px) {
  .leadership-page-container {
    max-width: 100vw;
    min-height: 310vh;
    display: flex;
    align-items: center;
    padding-top: 10vh;
    flex-direction: column;
  }
}

.exec-table {
  height: 180vh;
  width: 60vw;
  margin-bottom: 10vh;
  margin-top: 10vh;
}

@media screen and(max-width: 1300px) {
  .exec-table {
    width: 80vw;
  }
}

@media screen and(max-width: 1130px) {
  .exec-table {
    width: 95vw;
  }
}

@media screen and(max-width: 630px) {
  .leadership-page-container {
    max-width: 95vw;
    min-height: 550vh;
    display: flex;
    align-items: center;
    padding-top: 10vh;
    flex-direction: column;
  }
}

.exec-row {
  height: 45vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

#exec-row-1-2 {
  height: 50vh;
}

.exec-cell {
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
}

.exec-cell-2 {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
}

.exec-cell-1 {
  width: 95vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
  margin-bottom: 1vh;
}

.exec-pos {
  color: #26234F;
  font-size: 30px;
  font-weight: bold;
  font-style: italic;
  transform: translateY(-30%);
  width: 80%;
  text-align: center;
}

#advait-pos {
  font-size: 25px;
}

#gio-name-2 {
  font-size: 25px;
}

.exec-pos2 {
  color: #4E5AAF;
  font-size: 24px;
  font-weight: bold;
  font-style: italic;
  width: 100%;
  text-align: center;
  transform: translateY(-30%);
}

.exec-name {
  color: #26234F;
  font-size: 25px;
  transform: translateY(-20%);
  text-align: center;
}

.about-page-container {
  width: 100%;
  max-width: 100%;
  height: 240vh;
  display: flex;
  padding-top: 10vh;
  flex-direction: column;
  overflow: hidden;
}

.about-row {
  min-height: 50vh;
  width: 100%;
  max-width: 100%;
  display: flex;
  overflow-x: hidden;
  overflow: hidden;
}

#about-row-2 {
  margin-top: 10vh;
  height: 70vh;
  overflow: hidden;
}

#about-row-1 {
  height: 70vh;
  overflow-x: hidden;
  overflow: hidden;
}

.about-cell {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  overflow: hidden;
  overflow-x: hidden;
}
#about-20 {
  overflow-x: hidden;
}

#about-row-3 {
  margin-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#trad {
  z-index: 100;
  position: absolute;
}

#hex_3 {
  z-index: 50;
  position: absolute;
  overflow: hidden;
  max-width: 100%; /* Ensure it doesn't exceed the width of its parent */
  max-height: 100%
}

.about-cell-1-hex-3 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: right;
}

#arrow_3 {
  width: 49%;
  height: auto;
  position: absolute;
  transform: translateX(0.9%) translateY(105%);
}

#tech_tower {
  z-index: 100;
  position: absolute;
}

#hex_4 {
  z-index: 50;
  position: absolute;
  transform: translateX(-100%) translateY(-35%);
}

#arrow_4 {
  z-index: 101;
  width: 45%;
  height: auto;
  position: absolute;
  transform: translateX(-8%) translateY(110%);
}

.about-page-container-1 {
  width: 100%;
  max-width: 100%;
  height: 180vh;
  display: flex;
  padding-top: 10vh;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

#about_intro-1 {
  width: 50vw;
  height: auto;
}

#about_2-1 {
  margin-top: 10vh;
  width: 50vw;
  height: auto;
}

#about_3-1 {
  margin-top: 10vh;
  width: 75vw;
  height: auto;
}

@media screen and (max-width: 1100px) {
  .about-page-container-1 {
    height: 160vh;
  }
}

@media screen and (max-width: 975px) {
  .about-page-container-1 {
    height: 145vh;
  }
}

@media screen and (max-width: 850px) {
  .about-page-container-1 {
    height: 145vh;
  }
  #about_intro-1 {
    width: 60vw;
  }

  #about_2-1 {
    width: 60vw;
  }

  #about_3-1 {
    width: 80vw;
  }
}

@media screen and (max-width: 750px) {
  .about-page-container-1 {
    height: 135vh;
  }
  #about_intro-1 {
    width: 60vw;
  }

  #about_2-1 {
    width: 60vw;
  }

  #about_3-1 {
    width: 80vw;
  }
}

@media screen and (max-width: 650px) {
  .about-page-container-1 {
    height: 120vh;
  }
  #about_intro-1 {
    width: 60vw;
  }

  #about_2-1 {
    width: 60vw;
  }

  #about_3-1 {
    width: 80vw;
  }
}

@media screen and (max-width: 575px) {
  .about-page-container-1 {
    height: 120vh;
  }
  #about_intro-1 {
    width: 70vw;
  }

  #about_2-1 {
    width: 70vw;
  }

  #about_3-1 {
    width: 90vw;
  }
}

@media screen and (max-width: 500px) {
  .about-page-container-1 {
    height: 110vh;
  }
  #about_intro-1 {
    width: 70vw;
  }

  #about_2-1 {
    width: 70vw;
  }

  #about_3-1 {
    width: 90vw;
  }
}

@media screen and (max-width: 450px) {
  .about-page-container-1 {
    height: 110vh;
  }
  #about_intro-1 {
    width: 75vw;
  }

  #about_2-1 {
    width: 75vw;
  }

  #about_3-1 {
    width: 90vw;
  }
}

@media screen and (max-width: 400px) {
  .about-page-container-1 {
    height: 110vh;
  }
  #about_intro-1 {
    width: 75vw;
  }

  #about_2-1 {
    width: 75vw;
  }

  #about_3-1 {
    width: 90vw;
  }
}

@media screen and (max-width: 350px) {
  .about-page-container-1 {
    height: 90vh;
  }
  #about_intro-1 {
    width: 75vw;
  }

  #about_2-1 {
    width: 75vw;
  }

  #about_3-1 {
    width: 90vw;
  }
}

@media screen and (max-width: 300px) {
  .about-page-container-1 {
    height: 80vh;
  }
  #about_intro-1 {
    width: 75vw;
  }

  #about_2-1 {
    width: 75vw;
  }

  #about_3-1 {
    width: 90vw;
  }
}

.project-container {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 100%;
  height: 250vh;
  display: flex;
  padding-top: 10vh;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.project-row {
  height: 140vh;
  width: 100%;
  max-width: 100%;
  display: flex;
}

.project-cell {
  width: 50%;
  height: 100%;
  display: flex;
  padding-top: 5vh;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
  overflow-x: hidden;
  padding-left: 10%;
  padding-right: 10%;
  color: #1C2441;
  font-size: 25px;
  overflow: hidden;
}

.project-cell-1 {
  width: 100%;
  height: 130vh;
  display: flex;
  padding-top: 5vh;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
  overflow-x: hidden;
  padding-left: 10%;
  padding-right: 10%;
  color: #1C2441;
  font-size: 20px;
  overflow: hidden;
}

#project-cell-1-2 {
  height: 110vh;
}

.project-title {
  margin-top: 5vh;
  margin-bottom: 1vh;
}

.poker-title {
  margin-top: 5vh;
  margin-bottom: 1vh;
}

.apply-button {
  margin-top: 0vh;
  height: 75px;
  width: 225.5px;
  color: #26234F;
  font-weight: bold;
  background-color: #FED780;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-decoration: none;
  font-size: 30px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
}

.project-img {
  width: 60%;
  height: auto;
}

.project-img-1 {
  max-width: 100%;
  height: auto;
}

.project-title-1 {
  margin-top: 5vh;
  margin-bottom: 1vh;
  max-width: 100%;
}

.project-container-1 {
  margin-bottom: 10vh;
}

.apply-button-1 {
  margin-top: 0vh;
  height: 75px;
  width: 225.5px;
  color: #26234F;
  font-weight: bold;
  background-color: #FED780;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  text-decoration: none;
  font-size: 30px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1350px) {
  .project-container {
    width: 100%;
    max-width: 100%;
    height: 250vh;
    display: flex;
    padding-top: 10vh;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .project-row {
    height: 140vh;
    width: 100%;
    max-width: 100%;
    display: flex;
  }

  .project-cell {
    width: 50%;
    height: 100%;
    display: flex;
    padding-top: 5vh;
    flex-direction: column;
    align-items: center;
    border: 1px solid white;
    overflow-x: hidden;
    padding-left: 10%;
    padding-right: 10%;
    color: #1C2441;
    font-size: 1.85vw;
    overflow: hidden;
  }

  .project-title {
    margin-top: 5vh;
    margin-bottom: 1vh;
    width: 30vw;
  }

  .poker-title {
    margin-top: 5vh;
    margin-bottom: 1vh;
  }

  .apply-button {
    margin-top: 3vh;
    height: 4vw;
    width: 12vw;
    color: #26234F;
    font-weight: bold;
    background-color: #FED780;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    text-decoration: none;
    font-size: 2vw;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  }

  .project-img {
    width: 60%;
    height: auto;
  }
}


#project-row-2 {
  margin-top: 10vh;
}

@media screen and (max-width: 1200px) {
  .project-container {
    width: 100%;
    max-width: 100%;
    height: 250vh;
    display: flex;
    padding-top: 0vh;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }

  .project-row {
    height: 110vh;
    width: 100%;
    max-width: 100%;
    display: flex;
  }
  #project-row-2 {
    margin-top: 0vh;
    height: 100vh;
  }
}

@media screen and (max-width: 975px) {
  .project-container {
    width: 100%;
    max-width: 100%;
    height: 200vh;
    display: flex;
    padding-top: 0vh;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
  }

  .project-row {
    height: 90vh;
    width: 100%;
    max-width: 100%;
    display: flex;
  }
  #project-row-2 {
    margin-top: 0vh;
    height: 80vh;
  }
}

@media screen and (max-width: 780px) {
  .project-container {
    width: 100%;
    max-width: 100%;
    height: 180vh;
    display: flex;
    padding-top: 0vh;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
  }

  .project-row {
    height: 80vh;
    width: 100%;
    max-width: 100%;
    display: flex;
  }
  #project-row-2 {
    margin-top: 0vh;
    height: 80vh;
  }
}

.nav-bar-outer-container {
  height: 10vh;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.nav-bar-inner-container {
  height: 70%;
  width: 90%;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
}

.nav-bar-logo {
  width: 250px;
  height: auto;
  margin-right: 4vw;
}

.nav-bar-pages {
  color: #1C2441;
  font-weight: bold;
  font-size: 110%;
  text-decoration: none;
}

.nav-bar-contact-button {
  margin-left: 1%;
  height: 50px;
  width: 150px;
  color: white;
  font-weight: bold;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #30376B;
  border-radius: 6px;
  text-decoration: none;
}

.nav-bar-toggle {
    display: none; /* Hide by default */
    height: 100%;
}

.nav-links {
    display: flex; /* Default display */
    width: 90%;
    justify-content: space-evenly;
    align-items: center;
}

.nav-links.show {
    display: block; /* Show when toggled */
}

.nav-link-adjuster {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 5%;
}

/* Responsive */
@media screen and (max-width: 1850px) {
    .nav-bar-toggle {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 24px; /* Adjust based on your design */
        width: 30px; /* Adjust based on your design */
        padding: 0;
    }

    .hamburger-icon {
        display: block;
        width: 32px; /* Width of the hamburger lines */
        height: 10px; /* Height of the hamburger lines */
        background-color: #1C2441; /* Color of the hamburger lines */
        margin: 2px 0; /* Spacing between the lines */
        border-radius: 5px; /* Rounded corners */
    }

    .nav-links {
        display: none; /* Hide links by default on small screens */
    }

    .nav-links.show {
        display: block; /* Show when toggled */
    }

    .nav-bar-pages, .side-nav-bar-contact-button {
        display: block; /* Stack links vertically */
    }
}

@media screen and (max-width: 1500px) {
  .home-section-title {
    font-size: 40px;
    font-weight: bold;
    color: #30376B;
    justify-content: space-evenly;
    height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

  }
  .line-img {
    display: none;
  }
}

@media screen and (max-width: 980px) {

}

.side-nav-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  flex-direction: row; /* Ensure the children are arranged in a row */
}

.transparent-side {
  width: 70%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}

.opaque-side {
  width: 30%;
  height: 100%;
  background-color: white;
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2);
}

#side-nav-logo {
  width: 80%;
  height: auto;
}

.side-nav-bar-links-container {
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: space-evenly;
  align-items: center;
}

.side-nav-logo-container {
  display: flex;
  justify-content: center;
}

.side-nav-bar-pages {
  color: #1C2441;
  font-weight: bold;
  font-size: 1.5rem; /* Use rem or em for better responsiveness */
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .side-nav-bar-pages {
    font-size: 1.5rem; /* Adjust as needed */
  }
  .opaque-side {
    width: 40%;
  }
  
  .transparent-side {
    width: 60%;
  }

  .close-button {
    transform: translateX(-20%);
    background-color: #30376B;
  }
}

@media screen and (max-width: 768px) {
  .side-nav-bar-pages {
    font-size: 0.6rem; /* Adjust as needed */
  }
}


.side-nav-bar-contact-button {
  margin-left: 1%;
  height: 60px;
  width: 175px;
  color: white;
  font-weight: bold;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #30376B;
  border-radius: 6px;
  text-decoration: none;
  font-size: 1.2rem;
}

.close-button {
  position: absolute;
  top: 50%;
  left: 70%;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  width: 60px; /* Adjust the width as needed */
  height: 100px; /* Height should be half of the width */
  border-top-left-radius: 0;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 100px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-nav-icon {
  width: 150px;
  height: auto;
  color: #FFD983;
}

@media screen and (max-width: 1200px) {
  .side-nav-bar-pages {
    font-size: 1.5rem; /* Adjust as needed */
  }
  .opaque-side {
    width: 40%;
  }
  
  .transparent-side {
    width: 60%;
  }

  .close-button {
    left: 61%;
  }
}

@media screen and (max-width: 940px) {
  .side-nav-bar-pages {
    font-size: 1.5rem; /* Adjust as needed */
  }
  .opaque-side {
    width: 50%;
  }
  
  .transparent-side {
    width: 50%;
  }

  .close-button {
    left: 51.5%;
  }
}

@media screen and (max-width: 680px) {
  .side-nav-bar-pages {
    font-size: 1.5rem; /* Adjust as needed */
  }
  .opaque-side {
    width: 60%;
  }
  
  .transparent-side {
    width: 40%;
  }

  .close-button {
    left: 42%;
  }
}

@media screen and (max-width: 500px) {
  .side-nav-bar-pages {
    font-size: 1.5rem; /* Adjust as needed */
  }
  .opaque-side {
    width: 100%;
  }
  
  .transparent-side {
    width: 0%;
  }

  .close-button {
    left: 2%;
  }
}

/* Phone */
@media screen and (max-width: 450px) {
  .home-title {
    font-size: 12vw;
    width: 100%;
    margin-bottom: 2%;
    max-width: 80%;
  }

  .vector-img {
    width: 90%;
    height: auto;
  }

  .home-desc {
    margin-top: 2%;
    width: 80%;
    font-size: 4vw;
  }

  .arrow {
    top: 100%;
  }

  .exec-table {
    height: 575vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-cell-1 {
    margin-bottom: vh;
  }
  
  #partners-table {
    
    height: 60vh;
  }
}

/* Test */